import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import chunk from "lodash/chunk"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Seamless from "../components/Seamless"
import MenuLink from "../components/NavigationBar/MenuLink"
import Button from "../components/Buttons/Button"

import halfMark from "../images/half-mark.svg"

const Homepage = ({ pageContext, data }) => {
  const post = data.allUniquePagesJson.nodes[0]

  const [imageStrip] = chunk(post.imageStrip, 7)

  return (
    <Layout className="main-homepage">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      <div className="hero">
        <ImageMeta
          publicId={post.hero.imageId}
          className="hero__image"
          cloudName="nuvolum"
          width="auto"
          responsive
          responsiveUseBreakpoints="true"
        />

        <div className="hero__content">
          <MarkdownViewer markdown={post.hero.content} />
          <Button
            href={post.hero.buttons[0].button.href}
            buttonText={post.hero.buttons[0].button.buttonText}
            external
          />
        </div>
      </div>

      {/* <div className="home-facewall">
          {stripOne.map(img => (
            <React.Fragment key={img}>
              <ImageMeta
                publicId={img.imageId}
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
              />
            </React.Fragment>
          ))}
        </div> */}

      {/* <div className="section">
            <div className="our-mission">
              <ImageMeta
                publicId={post.ourMission.imageId}
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
              />
              <img src={halfMark} alt="" />
              <div>
                <MarkdownViewer markdown={post.ourMission.content} />
              </div>
            </div>
        </div> */}

      <div className="section flyer-info">
        <div className="columns">
          <div className="column is-3 is-hidden-tablet-only"></div>
          <div className="column column-left">
            <MarkdownViewer markdown={post.flyerInfo.columns[0].column.text} />
            {/* <Button
              href={post.flyerInfo.columns[0].column.buttons[0].button.href}
              buttonText={
                post.flyerInfo.columns[0].column.buttons[0].button.buttonText
              }
              internal
              contained
            /> */}
          </div>
          <div className="column is-1"></div>
          <div className="column is-8 column-right color-back">
            <MarkdownViewer markdown={post.flyerInfo.columns[1].column.text} />
            <Button
              href={post.flyerInfo.columns[1].column.buttons[0].button.href}
              buttonText={
                post.flyerInfo.columns[1].column.buttons[0].button.buttonText
              }
              external
              contained
            />
          </div>
          <div className="column is-3 is-hidden-tablet-only"></div>
        </div>
      </div>

      <div className="home-facewall">
        {imageStrip.map((img) => (
          <React.Fragment key={img}>
            <ImageMeta
              publicId={img.imageId}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
            />
          </React.Fragment>
        ))}
      </div>

      {/* <div className="section color-back">
          <div className="container mid-text">
            <h3>{post.midText}</h3>

            <Button
              href="https://g.tab32.com/c58Qgg"
              buttonText={"Register"}
              external
            />
          </div>
        </div> */}
    </Layout>
  )
}

Homepage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          content
          imageId
          buttons {
            button {
              appearance
              href
              buttonText
              destination
            }
          }
        }
        imageStrip {
          imageId
        }
        midText
        ourMission {
          imageId
          content
        }
        flyerInfo {
          columns {
            column {
              text
              buttons {
                button {
                  appearance
                  href
                  buttonText
                  destination
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Homepage
